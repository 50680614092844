<template>
  <main class="mx-auto max-w-screen-2xl px-4 py-2 overflow-y-auto mb-32 sm:mb-20">
    <header class="mb-4 flex flex-wrap items-baseline justify-between">
      <h2 class="my-1 text-2xl font-bold text-gray-800">Space details</h2>
    </header>


<div class="grid gap-6 md:grid-cols-2"><!-- review grid -->

    <div class="p-4 md:p-6 bg-gray-100 rounded-lg"><!-- form section -->
      <div class="flex flex-wrap h-full gap-4">
      <header class="w-full">
      <h3 class="text-lg font-bold text-gray-800">{{ currentSpace.name }}</h3><!-- space name -->
      <p class="text-sm text-gray-600">{{ currentSpace.description }}</p>
      </header>
      <main class="w-full mt-auto">
        <dl><!-- definition list -->
      <div class="mt-1 sm:grid sm:grid-cols-2 sm:gap-4">
        <dt class="text-sm text-gray-500">
          Building
        </dt>
        <dd class="text-sm font-medium text-gray-800">
          {{ selectedBuilding.name }}
        </dd>
      </div>
      <div class="mt-1 sm:grid sm:grid-cols-2 sm:gap-4">
        <dt class="text-sm text-gray-500">
          Address
        </dt>
        <dd class="text-sm font-medium text-gray-800">
          {{ selectedBuilding.address }}
        </dd>
      </div>

    </dl><!-- definition list -->
      </main>
      </div>
    </div><!-- /form section -->

     <div class="p-4 md:p-6 bg-gray-100 rounded-lg"><!-- form section -->

     <h4 class="text-md font-semibold text-gray-800 mb-3">Space</h4>

    <dl><!-- definition list -->
      <div class="mt-1 sm:grid sm:grid-cols-2 sm:gap-4">
        <dt class="text-sm text-gray-500">
          Space type
        </dt>
        <dd class="text-sm font-medium text-gray-800">
          {{ category }}
        </dd>
      </div>
      <div class="mt-1 sm:grid sm:grid-cols-2 sm:gap-4">
        <dt class="text-sm text-gray-500">
          Subcategory
        </dt>
        <dd class="text-sm font-medium text-gray-800">
          {{ subcategory }}
        </dd>
      </div>
      <div class="mt-1 sm:grid sm:grid-cols-2 sm:gap-4">
        <dt class="text-sm text-gray-500">
          Floor area (<span class="">{{ areaUnits }}</span>)
        </dt>
        <dd class="text-sm font-medium text-gray-800">
          {{ currentSpace.floor_area }}
        </dd>
      </div>
      <div class="mt-1 sm:grid sm:grid-cols-2 sm:gap-4">
        <dt class="text-sm text-gray-500">
          Ceiling height (<span class="">{{ heightUnits }}</span>)
        </dt>
        <dd class="text-sm font-medium text-gray-800">
          {{ currentSpace.ceiling_height }}
        </dd>
      </div>
    </dl><!-- definition list -->

    </div><!-- /form section -->

     <div class="p-4 md:p-6 bg-gray-100 rounded-lg"><!-- form section -->
      <h4 class="text-md font-semibold text-gray-800 mb-3">Occupants</h4>

    <dl><!-- definition list - THESE WILL DEPEND ON USER ENTRY -->

      <div class="mt-1 sm:grid sm:grid-cols-2 sm:gap-4">
        <dt class="text-sm text-gray-500">
           Peak occupancy
        </dt>
        <dd class="text-sm font-medium text-gray-800">
          {{ currentSpace.peak_occupancy }}
        </dd>
      </div>

      <div class="mt-1 sm:grid sm:grid-cols-2 sm:gap-4">
        <dt class="text-sm text-gray-500">
Occupancy duration (<span class="">min</span>)
        </dt>
        <dd class="text-sm font-medium text-gray-800">
          {{ currentSpace.occupant_duration_minutes }}
        </dd>
      </div>

      <div class="mt-1 sm:grid sm:grid-cols-2 sm:gap-4">
        <dt class="text-sm text-gray-500">
Occupant schedule
        </dt>
        <dd class="text-sm font-medium text-gray-800">
          {{ currentSpace.occupant_schedule }}
        </dd>
      </div>

      <div class="mt-1 sm:grid sm:grid-cols-2 sm:gap-4">
        <dt class="text-sm text-gray-500">
Occupant profile
        </dt>
        <dd class="text-sm font-medium text-gray-800">
          {{ currentSpace.occupant_profile }}
        </dd>
      </div>

      <div class="mt-1 sm:grid sm:grid-cols-2 sm:gap-4">
        <dt class="text-sm text-gray-500">
Activity type
        </dt>
        <dd class="text-sm font-medium text-gray-800">
          {{ currentSpace.activity }}
        </dd>
      </div>

      <div class="mt-1 sm:grid sm:grid-cols-2 sm:gap-4">
        <dt class="text-sm text-gray-500">
Mask type
        </dt>
        <dd class="text-sm font-medium text-gray-800">
          {{ currentSpace.mask_type }}
        </dd>
      </div>

      <div class="mt-1 sm:grid sm:grid-cols-2 sm:gap-4">
        <dt class="text-sm text-gray-500">
Mask use (<span class="">%</span>)
        </dt>
        <dd class="text-sm font-medium text-gray-800">
          {{ currentSpace.mask_use }}
        </dd>
      </div>

      <div class="mt-1 sm:grid sm:grid-cols-2 sm:gap-4">
        <dt class="text-sm text-gray-500">
Social distancing
        </dt>
        <dd class="text-sm font-medium text-gray-800">
          {{ currentSpace.social_distancing ? 'Yes' : 'No' }}
        </dd>
      </div>

      <div class="mt-1 sm:grid sm:grid-cols-2 sm:gap-4">
        <dt class="text-sm text-gray-500">
Vaccine mandate
        </dt>
        <dd class="text-sm font-medium text-gray-800">
          {{ currentSpace.vaccine_mandate ? 'Yes' : 'No' }}
        </dd>
      </div>

      <div class="mt-1 sm:grid sm:grid-cols-2 sm:gap-4">
        <dt class="text-sm text-gray-500">
Vaccination data
        </dt>
        <dd class="text-sm font-medium text-gray-800">
          —
        </dd>
      </div>

      <div class="mt-1 sm:grid sm:grid-cols-2 sm:gap-4">
        <dt class="text-sm text-gray-500">
Occupant vaccination (<span class="">%</span>)
        </dt>
        <dd class="text-sm font-medium text-gray-800">
          {{ currentSpace.background_vaccination_rate }}
        </dd>
      </div>

      <div class="mt-1 sm:grid sm:grid-cols-2 sm:gap-4">
        <dt class="text-sm text-gray-500">
Testing program
        </dt>
        <dd class="text-sm font-medium text-gray-800">
          {{ currentSpace.testing_program ? 'Yes' : 'No' }}
        </dd>
      </div>

    </dl><!-- definition list -->

    </div><!-- /form section -->

     <div class="p-4 md:p-6 bg-gray-100 rounded-lg"><!-- form section -->
      <h4 class="text-md font-semibold text-gray-800 mb-3">Building performance</h4>

    <dl><!-- definition list - THESE WILL DEPEND ON USER ENTRY -->

      <div class="mt-1 sm:grid sm:grid-cols-2 sm:gap-4">
        <dt class="text-sm text-gray-500">
          Supply airflow rate (<span class="">{{ airflowUnits }}</span>)
        </dt>
        <dd class="text-sm font-medium text-gray-800">
          {{ currentSpace.supply_airflow_rate }}
        </dd>
      </div>

      <div class="mt-1 sm:grid sm:grid-cols-2 sm:gap-4">
        <dt class="text-sm text-gray-500">
          Outside air (<span class="">{{ airflowUnits }}</span>)
        </dt>
        <dd class="text-sm font-medium text-gray-800">
          {{ currentSpace.outside_air_rate }}
        </dd>
      </div>

      <div class="mt-1 sm:grid sm:grid-cols-2 sm:gap-4">
        <dt class="text-sm text-gray-500">
         Filtration
        </dt>
        <dd class="text-sm font-medium text-gray-800">
          {{ currentSpace.filtration }}
        </dd>
      </div>

      <div class="mt-1 sm:grid sm:grid-cols-2 sm:gap-4">
        <dt class="text-sm text-gray-500">
          Temperature (<span class="">°{{ temperatureUnits }}</span>)
        </dt>
        <dd class="text-sm font-medium text-gray-800">
          {{ currentSpace.temperature }}
        </dd>
      </div>

      <div class="mt-1 sm:grid sm:grid-cols-2 sm:gap-4">
        <dt class="text-sm text-gray-500">
          Humidity (<span class="">%</span>)
        </dt>
        <dd class="text-sm font-medium text-gray-800">
          {{ currentSpace.relative_humidity_rate }}
        </dd>
      </div>


      <div class="mt-1 sm:grid sm:grid-cols-2 sm:gap-4">
        <dt class="text-sm text-gray-500">
Barriers
        </dt>
        <dd class="text-sm font-medium text-gray-800">
          {{ currentSpace.barriers ? 'Yes' : 'No' }}
        </dd>
      </div>


      <div class="mt-1 sm:grid sm:grid-cols-2 sm:gap-4">
        <dt class="text-sm text-gray-500">
Air-cleaning devices
        </dt>
        <dd class="text-sm font-medium text-gray-800">
          {{ currentSpace.air_cleaning_devices ? 'Yes' : 'No' }}
        </dd>
      </div>

      <div class="mt-1 sm:grid sm:grid-cols-2 sm:gap-4">
        <dt class="text-sm text-gray-500">
Total CADR (<span class="">{{ airflowUnits }}</span>)
        </dt>
        <dd class="text-sm font-medium text-gray-800">
          {{ currentSpace.total_clean_air_delivery_rate }}
        </dd>
      </div>

      <div class="mt-1 sm:grid sm:grid-cols-2 sm:gap-4">
        <dt class="text-sm text-gray-500">
Upper-room UVGI
        </dt>
        <dd class="text-sm font-medium text-gray-800">
          {{ currentSpace.upper_room_uvgi ? 'Yes' : 'No' }}
        </dd>
      </div>

    </dl><!-- definition list -->

    </div><!-- /form section -->

  </div><!-- /review grid -->

  </main>
</template>

<script>
import { ParticleOneSpaceCategory, ParticleOneUnit } from '@/network/models/ParticleOneCreateSpaceRequest';
import { mapGetters } from 'vuex';

export default {
  name: 'SpaceFormPageOne',
  computed: {
    areaUnits() {
      return this.currentSpace.units === ParticleOneUnit.Imperial ? 'sq. ft.' : 'm²';
    },
    heightUnits() {
      return this.currentSpace.units === ParticleOneUnit.Imperial ? 'ft.' : 'm';
    },
    temperatureUnits() {
      return this.currentSpace.units === ParticleOneUnit.Imperial ? 'F' : 'C';
    },
    airflowUnits() {
      return this.currentSpace.units === ParticleOneUnit.Imperial ? 'CFM' : 'L/s';
    },
    category() {
      return Object
        .keys(ParticleOneSpaceCategory)
        .find(key => ParticleOneSpaceCategory[key] === this.currentSpace.category);
    },
    subcategory() {
      return this.currentSpace.sub_category;
    },

    ...mapGetters(['selectedBuilding', 'currentSpace'])
  },
};
</script>