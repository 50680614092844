<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <TransitionRoot as="template" :show="showModal">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto">
      <!-- mx-auto max-w-screen-2xl px-4 py-2 overflow-y-auto mb-32 sm:mb-20 -->
      <div class="flex items-end justify-center h-screen text-center sm:block">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden inline-block align-middle h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="flex flex-col align-middle bg-white text-left shadow-xl overflow-hidden transform transition-all w-full h-full">
            <header class="w-full sticky top-0 bg-white border-b px-4 py-4 flex flex-wrap items-baseline justify-between">
              <h2 class="my-1 text-lg font-semibold">{{ name }} <span class="mx-2 text-gray-600 text-sm font-normal">{{ pageDescription }}</span></h2>
              <p class="my-1 text-sm">Need help? <a href="mailto:help@particleone.com" class="text-pink-500 underline hover:no-underline">Contact support</a></p>
            </header>

            <!-- These pages contain <main> tags -->
            <space-form-page-one v-if="userPage === 0" @formValues="update" :currentSpace="currentSpace" />
            <space-form-page-two v-else-if="userPage === 1" @formValues="update" :currentSpace="currentSpace" />
            <space-form-page-three v-else-if="userPage === 2" @formValues="update" :currentSpace="currentSpace" />
            <space-form-page-four v-else-if="userPage === 3" @formValues="update" :currentSpace="currentSpace" />
            <space-form-page-five v-else-if="userPage === 4" />

            <toast
              v-if="errorMessage != null"
              class="fixed bottom-16 left-0 right-0 m-4 shadow z-50"
              :message="errorMessage"
              @close="errorMessage = null"
            />
            
            <footer class="w-full fixed bg-white bottom-0 border-t px-4 py-3 flex flex-wrap items-baseline justify-between">
              <div class="my-1 inline-flex items-center">
                <button @click="onCancel" :disabled="isSavingSpace" :class="[isSavingSpace ? 'cursor-wait':'cursor-pointer']" class="disabled:opacity-50 mr-1 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-800 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300">
                  {{ cancelButtonText }}
                </button>

                <!-- Saving message - ONLY DISPLAY WHEN SAVING -->
                <div :class="[isSavingSpace ? 'visible': 'hidden']" class="inline-flex items-center px-2 text-sm font-medium rounded-md text-gray-400">
                  <svg class="mr-1 h-4 w-4 animate-spin" width="10" height="10" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle opacity="0.5" cx="10" cy="10" r="8" stroke="currentColor" stroke-width="3"/>
                    <path d="M10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2" stroke="currentColor" stroke-width="3" stroke-linecap="round"/>
                  </svg> {{ savingText }}
                </div>

                <!-- Saved message - ONLY DISPLAY FOR 3 SECONDS AFTER 'SAVING...' (remove 'hidden' class) -->
                <!-- <div :class="`${isSavingSpace} inline-flex items-center px-2 text-sm font-medium rounded-md text-gray-400`">
                  All changes saved!
                </div> -->

              </div>
              <div class="my-1">
                <button @click="decrementFormSection()" :disabled="isSavingSpace" :class="[isSavingSpace ? 'cursor-wait':'cursor-pointer', backButtonHidden ? 'hidden' : 'visible']" class="disabled:opacity-50 inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-800 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300">
                  Back
                </button>
                <button v-if="userPage < 4" :disabled="submitDisabled" @click="submitFormSection()" :class="[submitCursorClass]" class="ml-1 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-pink-600 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500 disabled:opacity-50">
                  {{ nextButtonText }}
                </button>
                <button v-else @click="submit()" :disabled="submitDisabled" :class="[submitCursorClass]" class="ml-1 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-pink-600 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500">
                  {{ submitButtonText }}
                </button>
              </div>
            </footer>
          </div>
        </TransitionChild>
      </div>
      <title-message-modal :showModal="showConfirmationModal">
        <template v-slot:header>
          <h1 class="text-lg font-medium text-gray-900">Save Changes?</h1>
        </template>

        <template v-slot:main>
          <p class="text-sm font-normal text-gray-500">Any unsaved changes will be lost. Are you sure?</p>
        </template>

        <template v-slot:footer>
          <button
            :disabled="isSavingSpace"
            class="disabled:opacity-50 inline-flex justify-center sm:items-center px-4 py-2 border border-gray-300 shadow-sm sm:text-sm font-medium rounded-md text-gray-800 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300"
            @click="submit()">
            Discard changes and close
          </button>
          <button v-if="isSavingSpace" :disabled="isSavingSpace" class="disabled:opacity-50 inline-flex justify-center sm:items-center px-4 py-2 border border-transparent sm:text-sm font-medium rounded-md shadow-sm text-white bg-pink-600 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500">
            <svg class="-ml-1 mr-2 h-3 w-3 animate-spin" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle opacity="0.5" cx="10" cy="10" r="8" stroke="currentColor" stroke-width="3"/>
              <path d="M10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2" stroke="currentColor" stroke-width="3" stroke-linecap="round"/>
            </svg>
            Saving...
          </button>
          <button
            v-else
            @click="submitFormSection().then(submit)"
            :disabled="isSavingSpace"
            class="disabled:opacity-50 inline-flex justify-center sm:items-center px-4 py-2 border border-transparent sm:text-sm font-medium rounded-md shadow-sm text-white bg-pink-600 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500">
            Save and close
          </button>
        </template>
      </title-message-modal>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { reactive, computed, ref } from 'vue';
import { Dialog, DialogOverlay, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { mapGetters, mapActions, useStore } from 'vuex';
import { getPage, missingSpaceFields } from '@/models/create-building';
import SpaceFormPageOne from './space-form-pages/SpaceFormPageOne.vue';
import SpaceFormPageTwo from './space-form-pages/SpaceFormPageTwo.vue';
import SpaceFormPageThree from './space-form-pages/SpaceFormPageThree.vue';
import SpaceFormPageFour from './space-form-pages/SpaceFormPageFour.vue';
import SpaceFormPageFive from './space-form-pages/SpaceFormPageFive.vue';
import TitleMessageModal from '@/components/modals/TitleMessageModal.vue';
import Toast from '@/components/modals/Toast.vue';

export default {
  name: 'CreateSpaceForm',
  components: {
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
    TitleMessageModal,
    Toast,
    SpaceFormPageOne,
    SpaceFormPageTwo,
    SpaceFormPageThree,
    SpaceFormPageFour,
    SpaceFormPageFive
  },
  props: {
    showModal: {
      type: Boolean,
      required: true
    },
    isEditing: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showConfirmationModal: false,
      errorMessage: null,
    };
  },
  computed: {
    submitDisabled() {
      return this.submitButtonDisabled || this.isSavingSpace;
    },
    submitCursorClass() {
      if (this.isSavingSpace) {
        return 'cursor-wait';
      } else if (this.submitDisabled) {
        return 'cursor-not-allowed';
      } else {
        return 'cursor-pointer';
      }
    },
    pageDescription() {
      if (this.userPage >= 4) {
        return this.isEditing ? 'Review' : 'Review and submit';
      }
      return `Step ${this.userPage + 1} of 4`;
    },
    backButtonHidden() {
      return (this.isEditing && this.userPage === 0) || (!this.isEditing && this.userPage < 2);
    },
    nextButtonText() {
      return this.userPage < 4 ? 'Save & Continue' : 'Save & Review space details';
    },
    submitButtonText() {
      return this.isEditing ? 'Finished' : 'Submit';
    },
    cancelButtonText() {
      return this.userPage === 0 && !this.isEditing ? 'Cancel' : 'Close';
    },
    isNewSpace() {
      return this.currentSpace.id == null;
    },
    name() {
      if (this.isEditing || !this.isNewSpace) {
        return `Edit ${this.currentSpace.name}`;
      }
      return 'New space';
    },
    savingText() {
      return this.isEditing ? 'Updating...' : 'Saving...';
    },
    ...mapGetters(['isSavingSpace', 'currentSpace'])
  },
  setup(props) {
    const store = useStore();
    const formValues = reactive({});
    const userPage = ref(props.isEditing ? 0 : getPage(store.getters.currentSpace));
    const submitButtonDisabled = computed(() => missingSpaceFields(formValues, userPage.value));

    // A function that accepts an object 
    // Transforms all keys from camelCase to snake_case 
    // Converts any numeric string values into a number type.
    function transformObject(obj) {
      const result = {};
      
      for (const key of Object.keys(obj)) {
        // convert any uppercase characters to lowercase and prepend an underscore
        const snakeCaseKey = key.replace(/([A-Z])/g, (match) => `_${match.toLowerCase()}`);
        result[snakeCaseKey] = typeof obj[key] === 'string' && !isNaN(Number(obj[key])) ? Number(obj[key]) : obj[key];
      }

      return result;
    }

    function valueChanged(values) {
      if (!values.airCleaningDevices && values.totalCleanAirDeliveryRate) {
        values.totalCleanAirDeliveryRate = null;
      }
      Object.assign(formValues, values);
      const transformedFormValues = transformObject(formValues);      
      store.state.dashboard.spaceResponse = {...store.state.dashboard.spaceResponse , ...transformedFormValues};
    }

    return {
      formValues,
      valueChanged,
      submitButtonDisabled,
      userPage
    };
  },
  methods: {
    update(formData) {
      this.valueChanged(formData);
    },
    incrementFormSection() {
      this.userPage++;
    },
    decrementFormSection() {
      this.userPage--;
    },
    async submit() {
      this.$emit('dismiss');
    },
    async submitFormSection() {
      if (this.userPage === 0 && this.isNewSpace) {
        try {
          await this.createSpace({ ...this.formValues, buildingId: this.$route.params.buildingId });
          this.incrementFormSection();
        } catch (error) {
          console.error('Error creating the space', error);
          this.errorMessage = 'There was a problem creating the space. Please try again later.';
        }
      } else if (this.userPage !== 0 || this.isEditing) {
        try {
          await this.updateSpace({ ...this.formValues, buildingId: this.$route.params.buildingId });
          this.incrementFormSection();
        } catch (error) {
          console.error('Error updating the space', error);
          this.errorMessage = 'There was a problem updating the space. Please try again later.';
        }
      }
    },
    onCancel() {
      if (!this.isNewSpace) {
        this.showConfirmationModal = true;
      } else {
        this.$emit('dismiss');
      }
    },
    ...mapActions(['createSpace', 'updateSpace']),
    
    beforeWindowUnload (e) {
      e.preventDefault(); // Cancel the event
      e.returnValue = ''; // Chrome requires returnValue to be set
    },
  },
  mounted() {
    window.addEventListener('beforeunload', this.beforeWindowUnload);
  },
  unmounted() {
    window.removeEventListener('beforeunload', this.beforeWindowUnload);
  }
};
</script>
