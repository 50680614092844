<template>
  <main class="mx-auto max-w-screen-2xl px-4 py-2 overflow-y-auto mb-32 sm:mb-20">
    <header class="mb-4 flex flex-wrap items-baseline justify-between">
      <h1 class="my-1 text-2xl font-bold">Space</h1>
      <p class="text-sm">
        We recommend consulting with your space’s <strong>building engineer</strong>, <strong>human resources manager</strong>, and <strong>health and safety officer</strong> to complete this process.
      </p>
    </header>

    <dynamic-form :form="form" @change="valueChanged" class="mt-4 md:mt-6 p-4 md:p-6 bg-gray-100 rounded-lg">
      <!-- Space type -->
      <template v-slot:spaceType="{ control, onChange, onFocus, onBlur }">
        <div class="grid md:grid-cols-6 gap-2 sm:gap-5 mb-6 sm:mb-3">
          <label for="space-type" class="sm:col-span-1 block text-sm font-medium text-gray-800 sm:pt-2">
            Space type
          </label>
          <div class="mt-1 sm:mt-0 sm:col-span-3 md:col-span-2">
            <select
              :id="control.name"
              v-if="control"
              v-model="control.value"
              :name="control.name"
              class="block max-w-lg w-full shadow-sm focus:border-pink-500 focus:ring-1 focus:ring-pink-500 sm:text-sm border-gray-300 rounded-md"
              @change="onChange"
              @focus="onFocus"
              @blur="onBlur"
              autocomplete=""
            >
              <option disabled selected value=undefined>Please select</option>
              <option v-for="space in spaceOptions" :key="space.value" :value="space.value">{{ space.label }}</option>
            </select>
          </div>
          <div class="sm:col-span-3 text-sm text-gray-600">The general category of how this space is typically used. <a href="https://help.particleone.com/space-type-learn-more/" target="_blank" class="inline-flex items-center text-pink-500 underline hover:no-underline">Learn more <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
            </svg></a></div>
          <div class="sm:col-span-1 md:-mt-9 text-red-600 italic text-xs font-medium">{{ validations(currentSpace, control.name ) }}</div>
        </div>
      </template>

        <!-- Subcategory -->
        <template v-slot:subcategory="{ control, onChange, onFocus, onBlur }">
          <div class="grid md:grid-cols-6 gap-2 sm:gap-5 mb-6 sm:mb-3">
            <label for="subcategory" class="sm:col-span-1 block text-sm font-medium text-gray-800 sm:pt-2">
              Subcategory
            </label>
            <div class="mt-1 sm:mt-0 sm:col-span-3 md:col-span-2">
              <select
                :id="control.name"
                v-if="control"
                v-model="control.value"
                :name="control.name"
                class="max-w-lg block focus:ring-pink-500 focus:border-pink-500 max-w-lg w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                @change="onChange"
                @focus="onFocus"
                @blur="onBlur"
                autocomplete=""
              >
                <option disabled selected value=undefined>Please select</option>
                <option v-for="subcategory in subcategoryOptions" :key="subcategory.value" :value="subcategory.value">{{ subcategory.label }}</option>
              </select>
            </div>
            <div class="sm:col-span-3 text-sm text-gray-600">A more detailed description of this space and its primary use. <a href="https://help.particleone.com/subcategory-learn-more/" target="_blank" class="inline-flex items-center text-pink-500 underline hover:no-underline">Learn more <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
            </svg></a></div>
            <div class="sm:col-span-1 md:-mt-9 text-red-600 italic text-xs font-medium">{{ validations(currentSpace, control.name ) }}</div>
          </div>
        </template>


      <!-- Units -->
      <template v-slot:units="{ control, onChange, onFocus, onBlur }">
        <div class="grid md:grid-cols-6 gap-2 sm:gap-5 mb-6 sm:mb-3">
          <label for="units" class="sm:col-span-1 block text-sm font-medium text-gray-800 sm:pt-2">
            Units
          </label>
          <div class="mt-1 sm:mt-0 sm:col-span-3 md:col-span-2">
            <select
              :id="control.name"
              v-if="control"
              v-model="control.value"
              :name="control.name"
              class="max-w-lg block focus:ring-pink-500 focus:border-pink-500 max-w-lg w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              @change="onChange"
              @focus="onFocus"
              @blur="onBlur"
              autocomplete=""
            >
              <option disabled selected value=undefined>Please select</option>
              <option v-for="unit in unitOptions" :key="unit.value" :value="unit.value">{{ unit.label }}</option>
            </select>
          </div>
          <div class="sm:col-span-3 text-sm text-gray-600">Default units for all measurements in this set-up process. <strong>Note:</strong> changing this selection will not convert previously entered values.</div>
          <div class="sm:col-span-1 md:-mt-9 text-red-600 italic text-xs font-medium">{{ validations(currentSpace, control.name ) }}</div>
        </div>
      </template>


      <!-- Floor area -->
      <template v-slot:floorArea="{ control, onChange, onFocus, onBlur }">
        <div class="grid md:grid-cols-6 gap-2 sm:gap-5 mb-6 sm:mb-3">
          <label for="area" class="sm:col-span-1 block text-sm font-medium text-gray-800 sm:pt-3">
            Floor area
          </label>
          <div class="mt-1 sm:mt-0 sm:col-span-3 md:col-span-2">
            <div class="mt-1 relative rounded-md shadow-sm w-48 max-w-xs">
              <input
                type="number"
                :id="control.name"
                v-if="control"
                v-model="control.value"
                :name="control.name"
                class="focus:ring-pink-500 focus:border-pink-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
                placeholder=""
                aria-describedby=""
                @change="onChange"
                @focus="onFocus"
                @blur="onBlur"
              >
              <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <span class="text-gray-600 sm:text-sm" id="">
                  {{ floorAreaUnits }}
                </span>
              </div>
            </div>
          </div>
          <div class="sm:col-span-3 text-sm text-gray-600">The floor area of the specific space identified in the subcategory. Do not deduct furnishings.</div>
          <div class="sm:col-span-1 md:-mt-9 text-red-600 italic text-xs font-medium">{{ validations(currentSpace, control.name ) }}</div><!-- errors -->
        </div>
      </template>


      <!-- Ceiling height -->
      <template v-slot:ceilingHeight="{ control, onChange, onFocus, onBlur }">
        <div class="grid md:grid-cols-6 gap-2 sm:gap-5">
          <label for="ceiling" class="sm:col-span-1 block text-sm font-medium text-gray-800 sm:pt-3">
            Ceiling height
          </label>
          <div class="mt-1 sm:mt-0 sm:col-span-3 md:col-span-2">
            <div class="mt-1 relative rounded-md shadow-sm w-48 max-w-xs ">
              <input
              type="number"
              :id="control.name"
              v-if="control"
              v-model="control.value"
              :name="control.name"
              class="focus:ring-pink-500 focus:border-pink-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
              placeholder=""
              aria-describedby=""
              @change="onChange"
              @focus="onFocus"
              @blur="onBlur"
            >
              <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <span class="text-gray-500 sm:text-sm" id="">
                  {{ ceilingHeightUnits }}
                </span>
              </div>
            </div>
          </div>
          <div class="sm:col-span-3 text-sm text-gray-600">The height from floor to ceiling. Do not include the area above drop ceilings. For areas with significant variability in ceiling height, use a typical height biased towards a smaller continuous height.</div>
          <div class="sm:col-span-1 md:-mt-9 text-red-600 italic text-xs font-medium">{{ validations(currentSpace, control.name ) }}</div>
        </div>
      </template>
    </dynamic-form>

  </main>
</template>

<script>
import { computed, reactive } from '@vue/runtime-core';
import { ParticleOneSpaceCategory, ParticleOneUnit, subcategory } from '@/network/models/ParticleOneCreateSpaceRequest';
import { createFormFields, validations } from '@/models/create-space-mapping';

export default {
  name: 'SpaceFormPageTwo',
  computed: {
    unitOptions() {
      return Object.entries(ParticleOneUnit).map(([key, value]) => ({ label: key, value: value }));
    },
    spaceOptions() {
      return Object.entries(ParticleOneSpaceCategory).map(([key, value]) => ({ label: key, value: value }));
    },
    subcategoryOptions() {
      return Object.entries(subcategory(this.formValues.spaceType)).map(([key, value]) => ({ label: key, value: value }));
    }
  },
  props: {
    currentSpace: {
      type: Object,
      required: true
    }
  },
  setup(props, context) {
    const formValues = reactive({});
    const floorAreaUnits = computed(() => formValues.units === ParticleOneUnit.Imperial ? 'sq. ft.' : 'm²');
    const ceilingHeightUnits = computed(() => formValues.units === ParticleOneUnit.Imperial ? 'feet' : 'm');
    const initialValues = {
      spaceType: props.currentSpace.category ?? undefined,
      subcategory: props.currentSpace.sub_category ?? undefined,
      units: props.currentSpace.units ?? ParticleOneUnit.Imperial,
      floorArea: props.currentSpace.floor_area,
      ceilingHeight: props.currentSpace.ceiling_height
    };
    // sync initial values to form
    Object.assign(formValues, { ...initialValues});
    const fields = createFormFields(1, initialValues);
    const form = computed(() => ({
      id: 'space-creation-page-two',
      fields: fields
    }));

    function subcategoryValue(newValues) {
      if (newValues.spaceType !== formValues.spaceType) {
        return null;
      }
      return newValues.subcategory;
    }

    function valueChanged(values) {
      Object.assign(formValues, { ...values, subcategory: subcategoryValue(values) });
      context.emit('formValues', formValues);
    }

    return {
      form,
      formValues,
      valueChanged,
      floorAreaUnits,
      ceilingHeightUnits,
      validations
    };
  }
};
</script>
